import { createApp } from 'vue'
import VueGtag from 'vue-gtag-next'
import App from './App.vue'
import i18n from './i18n'
import router from '@/routes'
import store from '@/store'
import getGoogleAnalyticsSettings from './utils/googleAnalytics'
import { init as initFS } from '@fullstory/browser'
import { sanitizeUrl } from '@braintree/sanitize-url'

import clickOutside from './plugins/click-outside'

import 'emerald-workbench/dist/emerald.pagarme.min.css'

// Jade
import '@stone-payments/jade-design-tokens/pagarme/theme.css'
import '@stone-payments/jade-design-tokens/pagarme/fonts.css'

if (['jpmorgan', 'pagarme'].includes(process.env.VUE_APP_THEME)) {
  store.dispatch('loadEmeraldByTheme', process.env.VUE_APP_THEME)
}

const app = createApp(App)

if (['production'].includes(process.env.VUE_APP_CORE_ENV)) {
  initFS({ orgId: 'BKP05' })

  const googleAnalyticsSettings = getGoogleAnalyticsSettings()
  app.use(VueGtag, googleAnalyticsSettings, router)
}

app.use(router)
app.use(store)
app.use(i18n)
app.config.globalProperties.$sanitize = sanitizeUrl

app.directive('click-outside', clickOutside)

app.mount('#app')
