<template>
  <div class="emd-onboarding__greeting eu-mb-4">
    <div class="emd-container _footer_centered _footer_end_md">
      <div
        class="emd-container__header eu-height_auto eu-justify_end eu-align-items_start"
      >
        <a
          href="javascript:void(0)"
          class="emd-text _color_tertiary"
          @click="close"
        >
          <p class="emd-btn _color_tertiary _type_link">
            <i class="fas fa-times"></i>
          </p>
        </a>
      </div>
      <div class="emd-container__content">
        <div class="emd-onboarding__image"></div>
        <h2 class="emd-text _size_page">
          {{ $t('hello', { name: name }) }}
        </h2>
        <i18n-t
          keypath="message"
          tag="div"
          class="emd-text eu-my_2"
        >
          <router-link
            :to="{ name: 'enterprises' }"
            class="emd-link _color_tertiary"
            >{{ $t('merchantTab') }}</router-link
          >
        </i18n-t>
      </div>
      <div class="emd-container__footer">
        <button
          class="emd-btn _color_tertiary"
          @click="close"
        >
          {{ $t('button') }}
        </button>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "hello": "Olá, {name}! Esse é o seu perfil.",
    "message": "Aqui você administra todos os seus dados pessoais. Para acompanhar e gerenciar suas vendas acesse a {0} no menu lateral e escolha uma conta em ver contas.",
    "merchantTab": "aba empresas",
    "button": "ok, entendi"
  },
  "en-US": {
    "hello": "Hello, {name}! This is your profile.",
    "message": "Here you can manage all your personal data. To track and manage all your sales access the {0} in the side menu and choose and account in the see accounts button.",
    "merchantTab": "merchant tab",
    "button": "ok, got it"
  }
}
</i18n>

<script>
export default {
  name: 'Greeting',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    themeMode () {
      return this.$store.getters.GET_THEME_MODE === 'dark' ? 'dark' : 'light'
    },
    appTheme () {
      return this.$store.getters.whitelabel.theme
    },
    folder () {
      return this.componentIsEnabled('footer', 'switchThemeMode')
        ? this.themeMode
        : this.appTheme
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    }
  }
}
</script>
