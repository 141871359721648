export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "pt-BR": {
        "hello": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Olá, ", _interpolate(_named("name")), "! Esse é o seu perfil."])},
        "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Aqui você administra todos os seus dados pessoais. Para acompanhar e gerenciar suas vendas acesse a ", _interpolate(_list(0)), " no menu lateral e escolha uma conta em ver contas."])},
        "merchantTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aba empresas"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ok, entendi"])}
      },
      "en-US": {
        "hello": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hello, ", _interpolate(_named("name")), "! This is your profile."])},
        "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Here you can manage all your personal data. To track and manage all your sales access the ", _interpolate(_list(0)), " in the side menu and choose and account in the see accounts button."])},
        "merchantTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["merchant tab"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ok, got it"])}
      }
    }
  })
}
