// Pass the Attributes as an Object
export default function setNewRelicData (attributes) {
  if (window.newrelic) {
    Object.keys(attributes).forEach(attr => {
      window.newrelic
        .interaction()
        .setAttribute('el', attributes[attr])
    })
  }
}
