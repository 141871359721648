const components = {
  apps: {
    dash: ['pagarme', 'jpmorgan'],
    hub: ['pagarme'],
    shop: ['pagarme'],
    pix: ['pagarme']
  },
  logo: {
    pagarme: ['pagarme'],
    jpmorgan: ['jpmorgan']
  },
  global: {
    onboarding: ['pagarme', 'jpmorgan']
  },
  globalError: {
    logo: ['pagarme']
  },
  globalLoader: {
    pagarme: ['pagarme', 'jpmorgan']
  },
  welcome: {
    createMerchant: ['pagarme']
  },
  profile: {
    theme: [],
    mode: [],
    switchLanguage: ['pagarme', 'jpmorgan']
  },
  header: {
    logoPagarme: ['pagarme']
  },
  footer: {
    textJPMorgan: ['jpmorgan'],
    textPagarme: ['pagarme'],
    switchThemeMode: [],
    status: ['pagarme'],
    help: ['pagarme'],
    documentation: ['pagarme', 'jpmorgan'],
    privacySecurity: ['jpmorgan'],
    urlSite: ['pagarme'],
    switchLanguage: ['jpmorgan'],
    privacyPolicy: ['pagarme'],
    termsOfUse: ['pagarme'],
    termsOfUseModal: [],
    secureEnv: ['pagarme', 'jpmorgan']
  },
  backofficeIcon: {
    pagarmeIcon: ['pagarme', 'jpmorgan']
  },
  menu: {
    tfaMenu: ['pagarme', 'jpmorgan']
  }
}

export default components
