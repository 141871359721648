const security = {
  path: '/security',
  component: () =>
    import(/* webpackChunkName: "security" */ '@/views/Security.vue'),
  meta: {
    title: 'BROWSER-TITLE.SECURITY'
  },
  children: [
    {
      name: 'security',
      path: '',
      component: () =>
        import(
          /* webpackChunkName: "security" */ '@/views/security/NewPassword.vue'
        )
    },
    {
      name: 'authentication',
      path: 'authentication',
      component: () =>
        import(
          /* webpackChunkName: "security" */ '@/views/security/TfaController.vue'
        )
    }
  ]
}

export default security
