export function formatErrorMessage (error, defaultError, customError = '') {
  let errors = []
  let arrayOfArrays
  const captureErrorLocale = error?.body?.notifications || error?.body
  const hasError = captureErrorLocale && Object.keys(captureErrorLocale)

  if (hasError) {
    arrayOfArrays = Object.values(captureErrorLocale)
    errors = arrayOfArrays.reduce((a, b) => [...a, ...b], [])
  } else if (customError) {
    errors = [customError]
  } else {
    errors = [defaultError]
  }
  return errors
}
