<template>
  <div class="el-core__header__left">
    <div class="emd-sidemenu__trigger">
      <emd-button-menu
        :openned="isOpen"
        @btnMenuClicked="toggleAppMenu"
      />
    </div>
    <div class="eu-ml_2 eu-ml_4_md">
      <div class="emd-logo-custom__image"></div>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "tooltip": {
      "backoffice": "Retornar ao backoffice",
      "account": "Selecionar Conta"
    },
    "status": {
      "test": "Teste",
      "inactive": "Inativo",
      "blocked": "Bloqueado",
      "active": "Ativo"
    }
  },
  "en-US": {
    "tooltip": {
      "backoffice": "Return to Backoffice",
      "account": "Select Account"
    },
    "status": {
      "test": "Test",
      "inactive": "Inactive",
      "blocked": "Blocked",
      "active": "Active"
    }
  }
}
</i18n>

<script>
import { EmdButtonMenu } from 'emerald-vue3/src/components'

export default {
  name: 'AppCoreHeaderLeft',
  components: {
    EmdButtonMenu
  },
  computed: {
    theme () {
      return process.env.VUE_APP_THEME
    },
    isPagarMe () {
      return this.componentIsEnabled('header', 'logoPagarme')
    }
  },
  props: {
    isOpen: {
      type: Boolean
    }
  },
  methods: {
    toggleAppMenu () {
      this.$emit('appMenuClicked')
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    }
  }
}
</script>
