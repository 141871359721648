import { ProfileTSInstance, catchError } from '@/store'

const actions = {
  GET_ACCOUNTS (context, params) {
    return ProfileTSInstance.account
      .getAll(params.merchantId, params.query)
      .then(res => Promise.resolve(res))
      .catch(err => catchError(err, context))
  }
}

export default {
  actions
}
