import { UtilitiesInstance, catchError } from '@/store'

export const state = () => ({
  timezones: [],
  languages: [],
  themes: []
})

const mutations = {
  SET_TIMEZONES (state, timezones) {
    state.timezones = timezones
  },
  SET_LANGUAGES (state, languages) {
    state.languages = languages
  },
  SET_THEMES (state, themes) {
    state.themes = themes
  }
}

const actions = {
  GET_TIMEZONES (context) {
    if (!context.state.timezones.length) {
      return UtilitiesInstance.commons
        .getTimezones()
        .then(res => {
          context.commit('SET_TIMEZONES', res)
          return Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
    } else {
      return Promise.resolve(context.state.timezones)
    }
  },
  GET_LANGUAGES (context) {
    if (!context.state.languages.length) {
      return UtilitiesInstance.commons
        .getLanguages()
        .then(res => {
          context.commit('SET_LANGUAGES', res)
          return Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
    } else {
      return Promise.resolve(context.state.languages)
    }
  },
  GET_THEMES (context) {
    if (!context.state.themes.length) {
      return UtilitiesInstance.commons
        .getThemes()
        .then(res => {
          context.commit('SET_THEMES', res)
          return Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
    } else {
      return Promise.resolve(context.state.themes)
    }
  }
}

const getters = {
  GET_COMMONS_TIMEZONES (state) {
    return state.timezones
  },
  GET_COMMONS_LANGUAGES (state) {
    return state.languages
  },
  GET_COMMONS_THEMES (state) {
    return state.themes
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
