export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "pt-BR": {
        "tooltip": {
          "backoffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retornar ao backoffice"])},
          "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar Conta"])}
        },
        "status": {
          "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teste"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativo"])},
          "blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueado"])},
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])}
        }
      },
      "en-US": {
        "tooltip": {
          "backoffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to Backoffice"])},
          "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Account"])}
        },
        "status": {
          "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
          "blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked"])},
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])}
        }
      }
    }
  })
}
