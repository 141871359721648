import store from '@/store'

const maintenance = {
  path: '/maintenance',
  name: 'maintenance',
  component: () =>
    import(
      /* webpackChunkName: "welcome-join" */ '@/components/project/globals/Maintenance.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (store.getters.maintenance) {
      store.commit('SET_GLOBAL_LOADING', false)
      next()
    } else {
      next({ name: 'default' })
    }
  },
  meta: {
    isMaintenance: true
  }
}

export default maintenance
