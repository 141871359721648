<template>
  <div
    class="el-core__header el-core__header_mobile eu-position_relative"
    :class="{ _open: isOpen, '_drawer-open': drawerOpen }"
  >
    <app-core-header-left
      :isOpen="isOpen"
      @appMenuClicked="toggleAppMenu"
    ></app-core-header-left>
    <app-core-header-right
      :merchant="merchant"
      :account="merchant.account"
      :profile="profile"
      :isOpen="drawerOpen"
      @openOnboarding="openOnboarding"
      @drawerMenuClicked="toggleDrawerMenu"
    ></app-core-header-right>
  </div>
</template>

<script>
import AppCoreHeaderLeft from '@/components/project/ui/menu/AppCoreHeaderLeft.vue'
import AppCoreHeaderRight from '@/components/project/ui/menu/AppCoreHeaderRight.vue'
export default {
  name: 'AppCoreHeader',
  components: {
    AppCoreHeaderLeft,
    AppCoreHeaderRight
  },
  props: {
    isOpen: {
      type: Boolean
    },
    drawerOpen: {
      type: Boolean
    }
  },
  computed: {
    profile () {
      return this.$store.getters.GET_USER_PROFILE
    },
    merchant () {
      return this.$store.getters.GET_MERCHANT_AUTHORIZATION
    }
  },
  methods: {
    toggleAppMenu () {
      this.$emit('appMenuClicked')
    },
    toggleDrawerMenu () {
      this.$emit('drawerMenuClicked')
    },
    openOnboarding () {
      this.$emit('openOnboarding')
    }
  }
}
</script>
