const whitelabel = {
  state: {
    whitelabel: {
      enabled: false,
      theme: 'pagarme'
    }
  },
  getters: {
    whitelabel (state) {
      return state.whitelabel
    }
  },
  mutations: {
    updateWhitelabel (state, value) {
      state.whitelabel = value
    }
  },
  actions: {
    updateWhitelabel ({ commit }, value) {
      commit('updateWhitelabel', value)
    },
    loadEmeraldByTheme ({ dispatch, commit }, theme) {
      const themecss = ['jpmorgan'].includes(theme) ? 'jpmorgan' : 'pagarme'
      const emerald = () =>
        import(`emerald-workbench/dist/emerald.${themecss}.min.css`)
      commit('updateWhitelabel', { theme: theme })
      if (['pagarme'].includes(theme)) dispatch('FORCE_LIGHT_MODE')
      emerald()
    }
  }
}
export default whitelabel
