import { IDInstance, catchError, ProfileTSInstance } from '@/store'
import { applyThemeOnBody } from '@/services/toggleThemeMode'
import { setCookieProfile, updateCookieProfile } from '../../support'
import Cookies from 'js-cookie'

export const state = () => ({
  user: undefined,
  userDataLoaded: false,
  preferences: ProfileTSInstance.me.getPreferences(),
  showGreeting: false,
  showModalHeader: false,
  isNewUser: false
})

const mutations = {
  SET_USER (state, user) {
    state.user = user
  },
  SET_USER_PREFERENCES (state, payload) {
    state.preferences = payload
  },
  SET_THEME_MODE_VIEW (state, payload) {
    state.preferences.appearance.mode = payload
  },
  SET_USER_DATA_LOADED (state, payload) {
    state.userDataLoaded = payload
  },
  SET_SHOW_GREETING (state, showGreeting) {
    state.showGreeting = showGreeting
  },
  SET_SHOW_MODAL_HEADER (state, showModalHeader) {
    state.showModalHeader = showModalHeader
  },
  SET_IS_NEW_USER (state, isNewUser) {
    state.isNewUser = isNewUser
  }
}

const actions = {
  UPDATE_USER_PREFERENCES (context, preferences) {
    try {
      preferences.appearance.theme = process.env.VUE_APP_THEME
      ProfileTSInstance.me.setPreferences(preferences)
      if (context.state.preferences.language !== preferences.language) {
        location.reload()
      }
      context.commit('SET_USER_PREFERENCES', preferences)
      return true
    } catch (err) {
      catchError(err, context)
    }
  },
  FORCE_LIGHT_MODE ({ state, dispatch }) {
    const preferences = { ...state.preferences }
    preferences.appearance.mode = 'light'
    dispatch('UPDATE_USER_PREFERENCES', preferences)
  },
  GET_USER (context) {
    return ProfileTSInstance.me
      .get()
      .then(response => {
        context.commit('SET_USER', response)
        context.commit('SET_THEME_MODE_VIEW', response.theme_mode)
        context.commit('SET_USER_DATA_LOADED', true)
        applyThemeOnBody(response.theme_mode)

        if (!response.is_root) {
          context.dispatch('GET_ONBOARDING')
        }
        context.commit('SET_GLOBAL_LOADING', false)
        return Promise.resolve(response)
      })
      .catch(err => catchError(err, context))
  },
  UPDATE_USER (context, body) {
    return ProfileTSInstance.me.update(body)
  },
  UPDATE_PASSWORD (context, body) {
    return IDInstance.operation.changePassword(body)
  },
  GET_PERMISSIONS (context) {
    return ProfileTSInstance.me
      .permissions()
      .then(res => Promise.resolve(res))
      .catch(err => catchError(err, context))
  },
  GET_DEFAULT_PERMISSION (context) {
    return ProfileTSInstance.me
      .getDefaultPermission()
      .then((res) => {
        if (res) {
          context.commit('SET_MERCHANT', res)
        }
        return Promise.resolve(res)
      })
      .catch(err => catchError(err, context))
  },
  SET_LANGUAGE ({ dispatch, state }, lang) {
    const preferences = { ...state.preferences }
    preferences.language = lang
    dispatch('UPDATE_USER_PREFERENCES', preferences)
  },
  SET_THEME_MODE ({ dispatch, state }, mode) {
    const preferences = { ...state.preferences }
    preferences.appearance.mode = mode
    dispatch('UPDATE_USER_PREFERENCES', preferences)
  },
  SET_TIMEZONE (context, payload) {
    const preferences = { ...context.state.preferences }
    preferences.timezone = payload
    context.dispatch('UPDATE_USER_PREFERENCES', preferences)
  },
  SET_DEFAULT (context, { params, body }) {
    return ProfileTSInstance.me
      .setDefault(params, body)
      .then(res => Promise.resolve(res))
      .catch(err => catchError(err, context))
  },
  SHOW_ONBOARDING (context, onboarding) {
    const isNewUser = onboarding?.is_new_user || onboarding?.show_greeting
    const showModalHeader = onboarding?.show_modal_header || !onboarding?.show_greeting

    context.commit(
      'SET_SHOW_GREETING',
      onboarding.show_greeting
    )
    context.commit(
      'SET_SHOW_MODAL_HEADER',
      !!showModalHeader
    )
    context.commit(
      'SET_IS_NEW_USER',
      !!isNewUser
    )
  },
  ONBOARDING_CALL (context, onboarding) {
    const hasOnboarding = onboarding && Object.entries(onboarding).length
    if (hasOnboarding) {
      context.dispatch('SHOW_ONBOARDING', onboarding)
    } else {
      return ProfileTSInstance.onboarding
        .get('profile')
        .then(res => {
          const hasOnboarding = res && Object.entries(res).length
          if (hasOnboarding) {
            context.dispatch('SHOW_ONBOARDING', res)
          } else {
            context.commit('SET_SHOW_GREETING', true)
            context.commit('SET_SHOW_MODAL_HEADER', false)
            context.commit('SET_IS_NEW_USER', true)
          }
          context.commit('SET_GLOBAL_LOADING', false)
          setCookieProfile({ onboarding: { ...res } })
          return Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
    }
  },
  async GET_ONBOARDING (context) {
    const envAcronym = ProfileTSInstance.acronym

    try {
      const cookieProfile = Cookies.get(`${envAcronym}mp_profile`)
      const decodedCookie = atob(cookieProfile)
      const { onboarding } = await JSON.parse(decodedCookie)
      context.dispatch('ONBOARDING_CALL', onboarding)
    } catch (err) {
      context.dispatch('ONBOARDING_CALL')
    }
  },
  UPDATE_GREETING (context, showGreeting) {
    context.commit('SET_SHOW_GREETING', showGreeting)
    updateCookieProfile('onboarding')
    return ProfileTSInstance.onboarding.update('profile', {
      show_greeting: context.state.showGreeting,
      show_modal_header: context.state.showModalHeader,
      is_new_user: context.state.isNewUser
    })
  },
  UPDATE_MODAL_HEADER (context, showModalHeader) {
    context.commit('SET_SHOW_MODAL_HEADER', showModalHeader)
    updateCookieProfile('onboarding')
    return ProfileTSInstance.onboarding.update('profile', {
      show_greeting: context.state.showGreeting,
      show_modal_header: context.state.showModalHeader,
      is_new_user: context.state.isNewUser
    })
  }
}

const getters = {
  GET_USER_PROFILE (state) {
    return state.user
  },
  GET_USER_PREFERENCES (state) {
    return state.preferences
  },
  GET_THEME_MODE (state) {
    return state.preferences.appearance.mode
  },
  GET_LANGUAGE (state) {
    return state.preferences.language
  },
  GET_USER_DATA_LOADED (state) {
    return state.userDataLoaded
  },
  showGreeting (state) {
    return state.showGreeting
  },
  showModalHeader (state) {
    return state.showModalHeader
  },
  isNewUser (state) {
    return state.isNewUser
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
