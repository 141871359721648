<template>
  <div
    class="emd-sidemenu _with_userfooter"
    :class="{ _open: isOpen }"
  >
    <div class="emd-sidemenu__body">
      <app-menu :simple="false"></app-menu>
    </div>
  </div>
</template>

<script>
import AppMenu from '@/components/project/ui/menu/AppMenu.vue'

export default {
  name: 'AppSidemenu',
  components: {
    AppMenu
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  }
}
</script>
