import Cookies from 'js-cookie'
import { ProfileCore as ProfileTS } from 'profile-ts'

const dataCore = {
  environment: process.env.VUE_APP_CORE_ENV,
  theme: process.env.VUE_APP_THEME
}

const ProfileTSInstance = new ProfileTS(dataCore)
const envAcronym = ProfileTSInstance.acronym

export function setCookieProfile (data = {}) {
  const cookieData = Cookies.get(`${envAcronym}mp_profile`)

  if (cookieData) {
    Promise.resolve(atob(cookieData))
      .then(JSON.parse)
      .then(resCookieParse => {
        Cookies.set(
          `${envAcronym}mp_profile`,
          btoa(JSON.stringify({ ...resCookieParse, ...data })),
          { expires: 0.5 }
        )
      })
  } else {
    Cookies.set(`${envAcronym}mp_profile`, btoa(JSON.stringify({ ...data })), {
      expires: 0.5
    })
  }
}

export function updateCookieProfile (key) {
  const cookieData = Cookies.get(`${envAcronym}mp_profile`)
  if (cookieData) {
    Promise.resolve(atob(cookieData))
      .then(JSON.parse)
      .then(resCookieParse => {
        delete resCookieParse[key]
        if (Object.entries(resCookieParse).length) {
          Cookies.set(
            `${envAcronym}mp_profile`,
            btoa(JSON.stringify({ ...resCookieParse })),
            { expires: 0.5 }
          )
        } else {
          Cookies.remove(`${envAcronym}mp_profile`)
        }
      })
  }
}
