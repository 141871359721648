const toggleThemeMode = modeToggle => {
  const body = document.querySelector('body')
  if (modeToggle) {
    body.classList.remove('_mode_light')
    body.classList.contains('_mode_dark') || body.classList.add('_mode_dark')
  } else {
    body.classList.remove('_mode_dark')
  }
}

export const applyThemeOnBody = theme => {
  const body = document.querySelector('body')
  if (['pagarme'].includes(process.env.VUE_APP_THEME)) {
    body.classList.remove('_mode_dark')
    return
  }
  if (theme === 'dark') {
    body.classList.contains('_mode_dark') || body.classList.add('_mode_dark')
  } else {
    body.classList.remove('_mode_dark')
  }
}

export const saveTheme = theme => {
  theme.toLowerCase()
  if (theme === 'dark') toggleThemeMode(true)
  else toggleThemeMode(false)
}

export default toggleThemeMode
