import store from '@/store'

const user = {
  name: 'user',
  path: '/user',
  component: () => import(/* webpackChunkName: "user" */ '@/views/User.vue'),
  beforeEnter: (to, from, next) => {
    store.dispatch('GET_TIMEZONES')
    store.dispatch('GET_LANGUAGES')
    store.dispatch('GET_THEMES')

    if (from.fullPath === '/') {
      store.dispatch('GET_INVITES', { page: 1, size: 30 }).then(res => {
        if (res?.items?.length) next({ name: 'invites' })
        else next()
      })
    } else next()
  },
  meta: {
    title: 'BROWSER-TITLE.MY-PROFILE'
  }
}

export default user
