import { createStore } from 'vuex'
import i18n from '@/i18n'

import IDCore from 'id-js'
import UtilitiesCore from 'utilities-js'
import { ProfileCore } from 'profile-ts'

import authorization from './modules/authorization'
import profile from './modules/profile'
import commons from './modules/commons'
import application from './modules/application'
import merchant from './modules/merchant'
import invites from './modules/invites'
import account from './modules/account'
import role from './modules/role'
import whitelabel from './modules/whitelabel'
import tfa from './modules/tfa'

const data = {
  environment: process.env.VUE_APP_CORE_ENV,
  theme: process.env.VUE_APP_THEME
}

export const IDInstance = new IDCore(data)
export const UtilitiesInstance = new UtilitiesCore(data)
export const ProfileTSInstance = new ProfileCore(data)

export const catchError = function (error, context) {
  if (error.status === 401) {
    context.dispatch('DELETE_AUTHORIZATION')
    window.location.assign(process.env.VUE_APP_DEFAULT_REDIRECT)
  } else if (error.status === 412) {
    if (!error.body) error.body = {}
    const messages = [{ message: i18n.t('ALERT.PASSWORD-ERROR') }]
    if (error.body.errors) error.body.errors.push(messages)
    else error.body.errors = messages
  } else if (error.status !== 400) {
    context.commit('SET_GLOBAL_LOADING', false)
    context.commit('SET_GLOBAL_ERROR', { status: true, code: error.status })
  }
  if (window.newrelic) {
    window.newrelic.noticeError(error.message)
  }
  return Promise.reject(error)
}

export default createStore({
  modules: {
    authorization,
    profile,
    commons,
    application,
    merchant,
    invites,
    account,
    role,
    whitelabel,
    tfa
  },
  state () {
    return {
      maintenance: false
    }
  },
  getters: {
    maintenance (state) {
      return state.maintenance
    }
  }
})
