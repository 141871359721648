import { ProfileTSInstance, catchError } from '@/store'

export const state = () => ({
  data: {
    items: []
  },
  isLoading: true
})

const mutations = {
  SET_INVITE_LIST (state, payload) {
    state.data = payload
  },
  SET_INVITE_LOADER (state, payload) {
    state.isLoading = payload
  }
}

const actions = {
  GET_INVITES (context, payload) {
    context.commit('SET_INVITE_LOADER', true)
    return ProfileTSInstance.invite
      .getMine(payload)
      .then(response => {
        context.commit('SET_INVITE_LIST', response)
        context.commit('SET_INVITE_LOADER', false)
        return Promise.resolve(response)
      })
      .catch(err => catchError(err, context))
  },
  REFUSE_INVITE (context, inviteId) {
    context.commit('SET_INVITE_LOADER', true)
    return ProfileTSInstance.invite
      .refuse(inviteId)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(err => catchError(err, context))
  },
  ACCEPT_INVITE (context, inviteId) {
    context.commit('SET_INVITE_LOADER', true)
    return ProfileTSInstance.invite
      .accept(inviteId)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(err => catchError(err, context))
  },
  ACCEPT_ALL_INVITES (context, payload) {
    return ProfileTSInstance.invite
      .acceptAll(payload)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(err => catchError(err, context))
  },
  UPDATE_INVITE_LIST (context, payload) {
    context.commit('SET_INVITE_LIST', payload)
  }
}

const getters = {
  GET_INVITE_LIST (state) {
    return state.data
  },
  GET_INVITE_LIST_IS_LOADED (state) {
    return state.isLoading
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
