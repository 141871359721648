import { ProfileTSInstance, catchError } from '@/store'

export const state = () => ({
  isLoading: false,
  companies: [],
  merchants: []
})

const mutations = {
  SET_LOADING (state, loading) {
    state.isLoading = loading
  },
  SET_COMPANIES (state, companies) {
    state.companies = companies
  },
  SET_MERCHANTS (state, merchants) {
    state.merchants = merchants
  }
}

const actions = {
  CREATE_MERCHANT (context, body) {
    return ProfileTSInstance.merchant
      .create(body)
      .then(res => Promise.resolve(res))
      .catch(err => catchError(err, context))
  }
}

export default {
  state,
  mutations,
  actions
}
