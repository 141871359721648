import { ProfileTSInstance, IDInstance, catchError } from '@/store'
import { version } from '@/../package.json'
import { identify } from '@fullstory/browser'
import setNewRelicData from '../../utils/setNewRelicAttr'

export const state = () => ({
  user: undefined,
  merchant: {}
})

const mutations = {
  SET_PROFILE (state, user) {
    state.user = user
  },
  SET_MERCHANT (state, merchant) {
    state.merchant = merchant
  },
  UPDATE_USER_NAME (state, user) {
    state.user.first_name = user.first_name
    state.user.last_name = user.last_name
  }
}

const actions = {
  GET_USER_AUTHORIZATION (context, params = {}) {
    if (['wl_jp', 'jpmorgan', 'pagarme'].includes(process.env.VUE_APP_THEME)) {
      context.dispatch('loadEmeraldByTheme', process.env.VUE_APP_THEME)
    }

    return ProfileTSInstance.authorization
      .get(params, 'profile', process.env.VUE_APP_PROFILE_URL)
      .then(res => {
        const newRelicData = {
          id: res.user.id,
          username: `${res.user.first_name} ${res.user.last_name}`,
          email: res.user.email
        }
        if (res.merchant) {
          newRelicData.merchant_id = res.merchant.id
          context.commit('SET_MERCHANT', res.merchant)
        } else context.dispatch('GET_DEFAULT_PERMISSION')

        setNewRelicData(newRelicData)

        const userInfosToFS = {
          id: res.user.id,
          displayName: `${res.user.first_name} ${res.user.last_name}`,
          email: res.user.email,
          merchantId_str: res.merchant ? res.merchant.id : null,
          merchantName_str: res.merchant ? res.merchant.name : null,
          userType_str: res.merchant ? res.merchant.type.key : null,
          appVersion_str: version
        }

        if (['production'].includes(process.env.VUE_APP_CORE_ENV)) {
          identify(res.user.id, userInfosToFS)
        }
        return res
      })
      .then(res => {
        context.commit('SET_PROFILE', res.user)
        context.dispatch('GET_USER')
        return Promise.resolve(res)
      })
      .catch(err => {
        return catchError(err, context)
      })
  },
  UPDATE_USER_NAME (context, user) {
    context.commit('UPDATE_USER_NAME', user)
  },
  DELETE_AUTHORIZATION (context, logout = false) {
    context.commit('SET_GLOBAL_LOADING', true)

    return IDInstance.auth.connect
      .revocationToken()
      .catch(err => catchError(err, context))
      .finally(() => {
        ProfileTSInstance.authorization.deleteCookies('profile', logout)
        window.location.assign(process.env.VUE_APP_DEFAULT_REDIRECT)
        return Promise.resolve()
      })
  },
  REFRESH_TOKEN (context) {
    return IDInstance.auth.connect
      .refreshToken()
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(err => {
        return catchError(err, context)
      })
  }
}

const getters = {
  GET_PROFILE_AUTHORIZATION (state) {
    return state.user
  },
  GET_MERCHANT_AUTHORIZATION (state) {
    return state.merchant
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
