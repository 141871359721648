import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import home from './home'
import user from './user'
import security from './security'
import enterprises from './enterprises'
import invites from './invites'
import welcome from './welcome'
import maintenance from './maintenance'
import notFound from './notFound'

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
  routes: [
    home,
    user,
    security,
    enterprises,
    invites,
    welcome,
    maintenance,
    notFound
  ]
})

router.beforeEach((to, from, next) => {
  const profile = store.getters.GET_PROFILE_AUTHORIZATION

  if (store.getters.maintenance) {
    next({ name: 'maintenance' })
  } else if (to.name !== 'welcome' && !profile) {
    store.dispatch('GET_USER_AUTHORIZATION').then(() => {
      next()
    })
  } else next()
})

export default router
