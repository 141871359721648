import { ProfileTSInstance } from '@/store'

export const state = () => ({
  userTfa: '',
  resetCodeConfirmCopy: {
    open: false,
    returnForCopy: false
  }
})

const mutations = {
  SET_USER (state, user) {
    state.userTfa = user
  },
  CONFIRM_COPY (state) {
    state.resetCodeConfirmCopy.open = !state.resetCodeConfirmCopy.open
  },
  RETURN_FOR_COPY (state) {
    state.resetCodeConfirmCopy.returnForCopy = true
  }
}

const actions = {
  GET_USER_MFA (context) {
    return ProfileTSInstance.me.getUserMFA()
  },
  CREATE_TFA (context, payload) {
    return ProfileTSInstance.me.createUserMFA(payload)
  },
  MFA_ACTIVATE (context, code) {
    return ProfileTSInstance.me.getActiveUserMFA(code)
  },
  MFA_SEND (context) {
    return ProfileTSInstance.me.sendUserMFA()
  },
  MFA_DELETE (context, code) {
    return ProfileTSInstance.me.deleteUserMFA(code)
  }
}

export default {
  state,
  mutations,
  actions
}
