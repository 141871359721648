import store from '@/store'

const home = {
  path: '/',
  name: 'default',
  beforeEnter: (_to, _from, next) => {
    const profile = store.getters.GET_PROFILE_AUTHORIZATION

    store.dispatch('GET_PERMISSIONS').then(permissions => {
      if (
        permissions.companies.length ||
        permissions.merchants.length ||
        profile.is_employee
      ) {
        next({ name: 'enterprises' })
      } else next({ name: 'welcomeJoin' })
    })
  }
}

export default home
