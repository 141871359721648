import { log as logFS } from '@fullstory/browser'
import { version } from '@/../package.json'

export const state = () => ({
  globalLoading: true,
  globalError: false,
  globalErrorCode: '',
  appVersion: version
})

const mutations = {
  SET_GLOBAL_LOADING (state, payload) {
    state.globalLoading = payload
  },
  SET_GLOBAL_ERROR (state, { status, code }) {
    state.globalError = status
    state.globalErrorCode = code
    if (
      status &&
      code !== 404 &&
      code !== 403 &&
      ['production'].includes(process.env.VUE_APP_CORE_ENV)
    ) {
      logFS('error', 'Error: redirected to screen 500')
    }
  }
}

const actions = {
  SET_GLOBAL_LOADING ({ commit }, payload) {
    commit('SET_GLOBAL_LOADING', payload)
  },
  SET_GLOBAL_ERROR ({ commit }, { status, code }) {
    commit('SET_GLOBAL_ERROR', { status, code })
  }
}

const getters = {
  GET_GLOBAL_LOADING (state) {
    return state.globalLoading
  },
  GET_GLOBAL_ERROR (state) {
    return state.globalError
  },
  GET_APP_VERSION (state) {
    return state.appVersion
  },
  GET_GLOBAL_ERROR_CODE (state) {
    return state.globalErrorCode
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
