import store from '@/store'

const invites = {
  name: 'invites',
  path: '/invites',
  component: () =>
    import(/* webpackChunkName: "invites" */ '@/views/Invites.vue'),
  beforeEnter: (to, from, next) => {
    const profile = store.getters.GET_PROFILE_AUTHORIZATION

    if (profile.is_root) next({ name: 'default' })
    next()
  },
  meta: {
    title: 'BROWSER-TITLE.INVITES'
  }
}

export default invites
