<template>
  <nav class="eu-py_2_mobile eu-py_2_sm eu-no-padding_md">
    <emd-menu-sidebar
      :menu="menu"
      :route-active="$route.name"/>
  </nav>
</template>

<script>
import { EmdMenuSidebar } from 'emerald-vue3/src/components'

export default {
  name: 'AppMenu',
  components: { EmdMenuSidebar },
  props: {
    simple: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      openSection: ''
    }
  },
  computed: {
    defaultMenu () {
      return {
        enterprises: {
          icon: 'building',
          showSection: !this.isRoot,
          isActive: this.$route.path.includes('enterprises'),
          label: this.$t('SIDEMENU.ENTERPRISES'),
          to: { name: 'enterprises' }
        },
        security: {
          icon: 'lock-alt',
          showSection: true,
          isActive: this.$route.path.includes('security'),
          label: this.$t('SIDEMENU.SECURITY'),
          to: { name: 'security' }
        },
        user: {
          icon: 'user',
          showSection: true,
          isActive: this.$route.name.includes('user'),
          label: this.$t('SIDEMENU.USER'),
          to: { name: 'user' }
        },
        invites: {
          icon: 'envelope',
          showSection: !this.isRoot,
          isActive: this.$route.name.includes('invites'),
          label: this.$t('SIDEMENU.INVITES'),
          to: { name: 'invites' }
        }
      }
    },
    tfaMenu () {
      return {
        enterprises: {
          icon: 'building',
          showSection: !this.isRoot,
          isActive: this.$route.path.includes('enterprises'),
          label: this.$t('SIDEMENU.ENTERPRISES'),
          to: { name: 'enterprises' }
        },
        security: {
          icon: 'lock-alt',
          showSection: true,
          isActive: this.$route.path.includes('security'),
          label: this.$t('SIDEMENU.SECURITY'),
          children: {
            authentication: {
              showSection: true,
              isActive: this.$route.name.includes('authentication'),
              label: this.$t('SUBMENU.AUTHENTICATION'),
              to: { name: 'authentication' }
            },
            security: {
              showSection: true,
              isActive: this.$route.name.includes('security'),
              label: this.$t('SUBMENU.SECURITY'),
              to: { name: 'security' }
            }
          }
        },
        user: {
          icon: 'user',
          showSection: true,
          isActive: this.$route.name.includes('user'),
          label: this.$t('SIDEMENU.USER'),
          to: { name: 'user' }
        },
        invites: {
          icon: 'envelope',
          showSection: !this.isRoot,
          isActive: this.$route.name.includes('invites'),
          label: this.$t('SIDEMENU.INVITES'),
          to: { name: 'invites' }
        }
      }
    },
    tfaAllowedUser () {
      return this.componentIsEnabled('menu', 'tfaMenu')
    },
    menu () {
      if (this.tfaAllowedUser) {
        return this.tfaMenu
      } else {
        return this.defaultMenu
      }
    },
    isRoot () {
      return this.$store.getters.GET_PROFILE_AUTHORIZATION.is_root
    }
  },
  methods: {
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    }
  }
}
</script>
