<template>
  <div
    v-if="!isIE"
    id="app"
    class="jade"
  >
    <div
      v-if="isLoading && !hasError"
      class="el-section el-centered eu-align-items_center"
    >
      <emd-pagarme-loader
        class="fade-in"
        :color="'secondary'"
      />
    </div>
    <global-error
      v-if="!isLoading && hasError"
      :showLogo="showMundiLogo"
      :code="errorCode"
      :theme="theme"
    />
    <template v-else-if="!isLoading && !hasError">
      <router-view v-slot="{ Component }">
        <div v-if="isNotLayoutRoute">
          <component :is="Component" />
        </div>

        <div v-else>
          <motherboard>
            <transition
              :name="transitionName"
              mode="out-in"
            >
              <component :is="Component" />
            </transition>
          </motherboard>
        </div>
      </router-view>
    </template>
  </div>
</template>
<script>
import { GlobalError, EmdPagarmeLoader } from 'emerald-vue3/src/components'
import { applyThemeOnBody } from '@/services/toggleThemeMode'
import { mapGetters } from 'vuex'
import Motherboard from './components/project/ui/Motherboard.vue'

export default {
  name: 'App',
  components: {
    EmdPagarmeLoader,
    GlobalError,
    Motherboard
  },
  data () {
    return {
      userMenuState: false,
      mobileMenuState: false,
      transitionName: 'slide-right',
      isNotLayoutRoute: true
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'GET_GLOBAL_LOADING',
      hasError: 'GET_GLOBAL_ERROR',
      themeMode: 'GET_THEME_MODE',
      appVersion: 'GET_APP_VERSION',
      errorCode: 'GET_GLOBAL_ERROR_CODE'
    }),
    showMundiLogo () {
      return false
    },
    isIE () {
      return window.isIE
    },
    theme () {
      return process.env.VUE_APP_THEME
    }
  },
  beforeMount () {
    applyThemeOnBody(this.themeMode)
  },
  watch: {
    $route: {
      handler (to, from) {
        const toDepth = to.path.split('/').length
        const fromDepth = from.path.split('/').length
        this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'

        this.isNotLayoutRoute = to.meta.isNewUser || to.meta.isMaintenance

        this.$nextTick().then(() => {
          document.title = `${this.$t(to.meta.title)} - ${
            process.env.VUE_APP_TITLE
          }`
        })
      },
      deep: true
    }
  },
  mounted () {
    const meta = document.createElement('meta')
    meta.name = 'version'
    meta.content = this.appVersion
    document.getElementsByTagName('head')[0].appendChild(meta)
  },
  methods: {
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    }
  }
}
</script>
<style>
#atlwdg-trigger {
  background: var(--brand--le--primary-color--base);
  color: #fff !important;
  border: none;
  box-shadow: none;
  border-radius: 4px 0 0 0;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 11px;
  padding: 6px 12px;
}
/* show underline on links inside tables */
tr:hover .emd-link {
  text-decoration: underline;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translateX(calc(2em - 6px));
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translateX(calc(-2em - 6px));
}

.legend {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
}
</style>
