import store from '@/store'

const welcome = {
  name: 'welcome',
  path: '/welcome',
  redirect: { name: 'welcomeChoice' },
  component: () =>
    import(/* webpackChunkName: "welcome-index" */ '@/views/welcome/index.vue'),
  beforeEnter: (to, from, next) => {
    const inviteList = store.getters.GET_INVITE_LIST

    if (!inviteList.items.length) {
      store.commit('SET_GLOBAL_LOADING', true)
      store.dispatch('GET_INVITES', { page: 1, size: 30 }).then(res => {
        if (res.items.length) {
          const invitedFromWhiteLabel = res.items.some(
            item => item.is_white_label || false
          )
          const ownerInvites = res.items.filter(
            invite => invite.type.key === 'Owner'
          )

          if (invitedFromWhiteLabel) {
            store
              .dispatch('ACCEPT_ALL_INVITES')
              .then(() => {
                store
                  .dispatch('REFRESH_TOKEN')
                  .then(() => next({ name: 'enterprises' }))
                  .catch(() => next({ name: 'welcome' }))
                  .finally(() => store.commit('SET_GLOBAL_LOADING', false))
              })
              .catch(() => {
                next({ name: 'welcome' })
                store.commit('SET_GLOBAL_LOADING', false)
              })
          } else if (ownerInvites.length === 1 && res.items.length === 1) {
            const { id, merchant_id: merchantId } = ownerInvites[0]
            store
              .dispatch('ACCEPT_INVITE', id)
              .then(() => {
                store
                  .dispatch('REFRESH_TOKEN')
                  .then(() => {
                    window.location.assign(
                      `${process.env.VUE_APP_COMPANY_URL}/${merchantId}`
                    )
                  })
                  .catch(() => {
                    next({ name: 'welcomeJoin' })
                    store.commit('SET_GLOBAL_LOADING', false)
                  })
              })
              .catch(() => {
                next({ name: 'welcomeJoin' })
                store.commit('SET_GLOBAL_LOADING', false)
              })
          } else next({ name: 'welcomeJoin' })
        } else next({ name: 'enterprises' })
      })
    } else next()
    store.commit('SET_GLOBAL_LOADING', false)
  },
  meta: {
    title: 'BROWSER-TITLE.SECURITY',
    isNewUser: true
  },
  children: [
    {
      name: 'welcomeChoice',
      path: '/',
      component: () =>
        import(
          /* webpackChunkName: "welcome-choice" */ '@/views/welcome/Welcome.vue'
        ),
      meta: {
        title: 'BROWSER-TITLE.WELCOME'
      }
    },
    {
      name: 'welcomeCreate',
      path: 'create',
      component: () =>
        import(
          /* webpackChunkName: "welcome-create" */ '@/views/welcome/Create.vue'
        ),
      meta: {
        title: 'BROWSER-TITLE.CREATE-MERCHANT'
      },
      beforeEnter: (to, from, next) => {
        if (store.getters.whitelabel.enabled) {
          store.dispatch('SET_GLOBAL_ERROR', { status: true, code: 404 })
          next()
        } else {
          next()
        }
      }
    },
    {
      name: 'welcomeJoin',
      path: 'join',
      component: () =>
        import(
          /* webpackChunkName: "welcome-join" */ '@/views/welcome/Join.vue'
        ),
      meta: {
        title: 'BROWSER-TITLE.ACCEPT-INVITES'
      }
    }
  ]
}

export default welcome
