<template>
  <div id="app">
    <main
      class="el-core _fixed"
      v-show="!isLoading && !hasError"
    >
      <section class="el-core__wrapper eu-no-margin">
        <div class="el-main">
          <div
            class="el-main__navigation eu-mt_auto el-main__navigation_menu-sidebar"
            :class="{ _open: appMenuOpen }"
          >
            <app-core-header
              @appMenuClicked="toggleAppMenu"
              @drawerMenuClicked="toggleDrawerMenu"
              @openOnboarding="openOnboarding"
              :drawerOpen="drawerMenuOpen"
              :isOpen="appMenuOpen"
            ></app-core-header>

            <app-sidemenu
              @appMenuClicked="toggleAppMenu"
              :isOpen="appMenuOpen"
            />

            <app-user-menu></app-user-menu>
           </div>

          <div class="el-main__content el-main__content_xl">
            <div
              class="el-section"
              v-if="showGreeting"
            >
              <greeting
                :name="user.first_name"
                @close="closeGreeting"
              />
            </div>

            <slot />

            <emd-footer />
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import AppSidemenu from '@/components/project/ui/menu/AppSidemenu.vue'
import AppCoreHeader from '@/components/project/ui/menu/AppCoreHeader.vue'
import EmdFooter from '@/components/emerald/organisms/EmdFooter.vue'
import Greeting from '@/components/project/globals/Greeting.vue'
import { mapGetters } from 'vuex'
import AppUserMenu from '@/components/project/ui/menu/AppUserMenu'
export default {
  name: 'App',
  components: {
    AppUserMenu,
    AppSidemenu,
    EmdFooter,
    AppCoreHeader,
    Greeting
  },
  data () {
    return {
      appMenuOpen: false,
      drawerMenuOpen: false,
      transitionName: 'slide-right'
    }
  },
  computed: {
    ...mapGetters({
      userProfile: 'GET_PROFILE_AUTHORIZATION',
      isLoading: 'GET_GLOBAL_LOADING',
      hasError: 'GET_GLOBAL_ERROR',
      user: 'GET_USER_PROFILE'
    }),
    showGreeting () {
      return this.user && this.$store.getters.showGreeting
    },
    isNewUser () {
      return this.$store.getters.isNewUser
    }
  },
  methods: {
    toggleDrawerMenu () {
      this.drawerMenuOpen = !this.drawerMenuOpen
      if (this.drawerMenuOpen) this.appMenuOpen = false
    },
    toggleAppMenu () {
      this.appMenuOpen = !this.appMenuOpen
      if (this.appMenuOpen) this.drawerMenuOpen = false
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    },
    openOnboarding () {
      this.$store.dispatch('UPDATE_GREETING', true)
    },
    closeGreeting () {
      this.$store.dispatch('UPDATE_GREETING', false)
    }
  },
  watch: {
    $route: {
      handler (to, from) {
        // closes appMenu on route change
        if (to !== from && this.appMenuOpen) {
          this.appMenuOpen = false
        }
      },
      deep: true
    }
  },
  created () {
    if (window.newrelic) {
      window.newrelic
        .interaction()
        .setAttribute(
          'username',
          `${this.userProfile.first_name} ${this.userProfile.last_name}`
        )
        .setAttribute('email', this.userProfile.email)
    }
  }
}
</script>
<style>
/* show underline on links inside tables */
tr:hover .emd-link {
  text-decoration: underline;
}

._decoration_underline {
  text-decoration: underline;
}

@media (min-width: 1920px) {
  .el-main__content.el-main__content_xl {
    max-width: 1920px;
    margin: 0 auto;
  }
}
</style>
