<template>
  <div class="el-core__header__right eu-display_flex_sm eu-display_none_md">
    <div class="emd-drawer _right">
      <div
        class="emd-drawer__trigger eu-display_flex_sm eu-display_none_md"
        :class="{ _open: isOpen }"
      >
        <button
          class="emd-action-button"
          @click="toggleDrawerMenu"
        >
          <i class="emd-action-button__icon uil uil-ellipsis-v"></i>
        </button>
      </div>

      <div
        class="emd-drawer__content _simple eu-px_2 eu-py_2"
        :class="{ _open: isOpen, '_open-sm' : isOpen }"
      >
        <user-list-links :is-employee="isEmployee"></user-list-links>
      </div>
    </div>
  </div>
</template>

<script>
import UserListLinks from '@/components/project/globals/UserListLinks'

export default {
  name: 'AppCoreHeaderRight',
  components: {
    UserListLinks
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    merchant: {
      type: Object,
      default: null
    },
    account: {
      type: Object,
      default: null
    },
    profile: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      profileOpen: false
    }
  },
  computed: {
    user () {
      return this.$store.getters.GET_PROFILE_AUTHORIZATION
    },
    isEmployee () {
      return this.user.is_employee
    },
    urlBackoffice () {
      return `${process.env.VUE_APP_BACKOFFICE_URL}`
    }
  },
  methods: {
    toggleDrawerMenu () {
      this.$emit('drawerMenuClicked')
    }
  }
}
</script>
