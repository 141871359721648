import store from '@/store'

const enterprises = {
  path: '/enterprises',
  component: () =>
    import(/* webpackChunkName: "enterprises" */ '@/views/Enterprises.vue'),
  beforeEnter: (to, from, next) => {
    const profile = store.getters.GET_PROFILE_AUTHORIZATION

    if (profile.is_root) next({ name: 'default' })
    next()
  },
  meta: {
    title: 'BROWSER-TITLE.MERCHANTS'
  },
  children: [
    {
      path: '',
      name: 'enterprises',
      component: () =>
        import(
          /* webpackChunkName: "enterprises" */ '@/views/merchants/AllEnterprises.vue'
        )
    },
    {
      path: 'allcompanys',
      name: 'allCompanys',
      component: () =>
        import(
          /* webpackChunkName: "enterprises" */ '@/views/merchants/AllCompanies.vue'
        )
    },
    {
      path: 'allmerchants',
      name: 'allMerchants',
      component: () =>
        import(
          /* webpackChunkName: "enterprises" */ '@/views/merchants/AllMerchants.vue'
        )
    }
  ]
}

export default enterprises
