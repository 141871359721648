export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "pt-BR": {
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair"])},
        "backoffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acessar Backoffice"])}
      },
      "en-US": {
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
        "backoffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Backoffice"])}
      }
    }
  })
}
