<template>
  <emd-user-menu-footer :user="user">
    <emd-dropdown
      :active="isOpen"
      :balloon="true">
      <template v-slot:trigger>
        <span class="emd-action-button emd-tippy-ghost">
           <i class="uil uil-ellipsis-h emd-text _size_page"></i>
         </span>
      </template>
      <template v-slot:content>
        <user-list-links :is-employee="isEmployee"></user-list-links>
      </template>
    </emd-dropdown>
  </emd-user-menu-footer>
</template>

<script>
import UserListLinks from '@/components/project/globals/UserListLinks'
import { EmdUserMenuFooter, EmdDropdown } from 'emerald-vue3/src/components'

export default {
  name: 'AppUserMenu',
  components: {
    EmdUserMenuFooter,
    EmdDropdown,
    UserListLinks
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    user () {
      return this.$store.getters.GET_PROFILE_AUTHORIZATION
    },
    isEmployee () {
      return this.user.is_employee
    }
  }
}
</script>
